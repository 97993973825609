<template>
	<div>
		<v-row>
			<v-col>
				<MatrixAnswerCard
					v-for="item in items"
					:isOthers="true"
					:key="'card-' + item.id"
					:item="item"
					:index="item.id"
				></MatrixAnswerCard>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MatrixAnswerCard from '@/components/demo/MatrixAnswerCard.vue';

export default {
	name: 'MatrixCheckboxOthers',
	components: {
		MatrixAnswerCard
	},
	computed: {
		...mapGetters({
			items: 'stateMatrixEmptyItemAnswers',
			matrixItemAnswers: 'stateMatrixItemAnswers',
			matrixItemNotAnswered: 'stateMatrixItemNotAnswered'
		})
	},
	created () {
		this.items.forEach(item => {
			if (!this.matrixItemNotAnswered.includes(item.id)) {
				let index = this.matrixItemAnswers.findIndex(x => x.id == item.id)
				if (index == -1) {
					this.setMatrixItemNotAnswered([item])
				} else {
					let answer = this.matrixItemAnswers[index]
					item.value_1 = answer.value_1
					item.value_2 = answer.value_2
					item.value_3 = answer.value_3
					item.value_4 = answer.value_4
					item.value_5 = answer.value_5
				}
			}
		})
	},
	methods: {
		...mapActions([
			'setMatrixItemNotAnswered'
		])
	}
}

</script>