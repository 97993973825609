<template>
	<div>
		<v-row class="mot-survey-form-large">
			<v-col>
				<p class="text-subtitle-1 text-left">以下には司書課程で学ぶことや一部の司書課程で学んでいることを示しています。<br/>あなたが司書課程で学び、「興味関心を惹かれた」ことや「現在の仕事に役立った」と思われること、司書課程ではあまり学ばなかったと思うが、「興味関心を惹かれる」ことや「現在の仕事のために学びたかった」ことがあれば、全てお選びください。いずれも該当しない場合は「あてはまるものはない」だけをお選びください。<span class="star">*</span><br/>【4カテゴリ・39問＋自由回答】＜複数選択可＞</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2 id="scroll-to-0">図書館の基礎（11問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_basics"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>図書館サービス（9問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_service"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>図書館情報資源（8問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_information"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>選択科目（11問）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckbox
					:items="library_others"
					:headers="headers"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col><h2>その他（自由に追加してください）</h2></v-col>
		</v-row>
		<v-row class="mot-survey-form">
			<v-col>
				<MatrixCheckboxOthers/>
			</v-col>
		</v-row>
		<v-row>
			<v-spacer></v-spacer>
			<v-col><MatrixItemAddButton/></v-col>
			<v-spacer></v-spacer>
		</v-row>
		<v-row>
			<v-card
				class="mx-auto mt-10"
				max-width="900"
				tile
				flat
				v-if="notAnsweredId.length"
			>
				<p class="mb-5">未回答の項目があります。</p>
				<v-chip
					v-for="item in notAnsweredId"
					:key="'not-answered-' + item"
					class="ma-1"
					color="error"
					outlined
					link
					@click="scroll(item)"
				>
					{{ item }}
				</v-chip>
			</v-card>
		</v-row>
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 0em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 0em;
}

.mot-survey-form {
	margin-top: 1em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

.left {
	text-align: left !important;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'
import MatrixCheckbox from '@/components/demo/MatrixCheckbox'
import MatrixCheckboxOthers from '@/components/demo/MatrixCheckboxOthers'
import MatrixItemAddButton from '@/components/demo/MatrixItemAddButton'

export default {
	name: 'UserSurveyQ6',
	components: {
		MatrixCheckbox,
		MatrixCheckboxOthers,
		MatrixItemAddButton
	},
	data () {
		return {
			form: {
				motivation: null,
				certificate_year: null
			},
			rules: {
				required: value => !!value || '必須'
			},
			value_5: false,
			headers: [
				{ text: "ID", value: "id", align: "start" },
				{ text: "学びの内容", value: "text", align: "start", width: 250 },
				{ text: "司書課程で学び、興味を惹かれた", value: "value_1", align: "start" },
				{ text: "司書課程で学び、仕事に役立った", value: "value_2", align: "start" },
				{ text: "司書課程ではあまり学ばなかったと思うが、興味を惹かれる", value: "value_3", align: "start" },
				{ text: "司書課程ではあまり学ばなかったと思うが、仕事のために学びたかった", value: "value_4", align: "start" },
				{ text: "あてはまるものはない", value: "value_5", align: "start" }
			],
			library_basics: [
				{ id: 1, text: "図書や図書館の歴史", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 2, text: "国内の図書館の実態（先進的な実践事例や参考となる取組）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 3, text: "海外の図書館の実態（先進的な実践事例や参考となる取組）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 4, text: "図書館の意義や理念", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 5, text: "さまざまな館種の知識（学校図書館・大学図書館・専門図書館など）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 6, text: "生涯学習に関する知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 7, text: "法律の基礎的な知識（図書館法・社会教育法・個人情報保護法など）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 8, text: "著作権・知的財産権・ライセンスなどの知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 9, text: "地方公共団体における図書館政策や事業計画など", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 10, text: "図書館の事業評価（PDCAサイクルなど）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 11, text: "図書館の運営形態（直営・指定管理・業務委託など）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			library_service: [
				{ id: 12, text: "児童サービスの知識（乳幼児・ヤングアダルトを含む）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 13, text: "高齢者サービスの知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 14, text: "障害者サービスの知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 15, text: "イベント・展示の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 16, text: "接遇・クレーム対応の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 17, text: "図書館と地域の組織・団体・ボランティアの折衝・協力・コミュニケーション", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 18, text: "レファレンスツール（参考図書やデータベース）の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 19, text: "レファレンスツールを用いた探索技術", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 20, text: "利用者の情報ニーズを把握するための知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			library_information: [
				{ id: 21, text: "紙の情報資源を収集するための知識、出版流通の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 22, text: "紙の情報資源を管理するための知識（装備・修理・保存）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 23, text: "紙の情報資源を選定するための知識（選書、蔵書構築）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 24, text: "電子書籍・電子ジャーナル及び収集管理するための知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 25, text: "目録の知識・技術", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 26, text: "分類の知識・技術", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 27, text: "コンピュータなど情報通信機器に関する基礎的な知識・技術（Word、Excelなど）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 28, text: "コンピュータなど情報通信機器に関する応用的な知識・技術（プログラミング、図書館システムなど）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			library_others: [
				{ id: 29, text: "図書館の実務（図書館実習などで一定期間実務を経験する）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 30, text: "情報リテラシー教育（図書館ガイダンス、学習支援など）の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 31, text: "地域資料や古文書の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 32, text: "デジタルアーカイブや学術情報流通（オープンアクセス、オープンサイエンスなど）の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 33, text: "研究者が行う研究活動支援の知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 34, text: "ネットワーク・システムの導入及び運用・管理法など", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 35, text: "図書館の建設・改築等に関する知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 36, text: "図書館の予算・会計・契約に関する知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 37, text: "統計の基礎知識", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 38, text: "プレゼンテーションの経験（ブックトークなどを含む）", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false },
				{ id: 39, text: "グループワークの経験", value_1: false, value_2: false, value_3: false, value_4: false, value_5: false }
			],
			isMobile: false
		}
	},
	beforeDestroy() {
		if (typeof window === 'undefined') return
		window.removeEventListener('resize', this.onResize, { passive: true })
	},
	mounted() {
		this.onResize()
		window.addEventListener('resize', this.onResize, { passive: true })
	},
	computed: {
		...mapGetters({
			matrixItemNotAnswered: 'stateMatrixItemNotAnswered',
		}),
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		getNotAnsweredId() {
			return this.matrixItemNotAnswered
		},
		scroll (item) {
			if (this.isMobile) {
				this.scrollTo('scroll-to-' + Math.max(0, item - 1))
			} else {
				this.scrollTo('scroll-to-' + Math.max(0, item - 1))
			}
		},
		scrollTo (id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		},
		onResize () {
			this.isMobile = window.innerWidth < 1200
		}
	}
}
</script>