<template>
    <div class="text-center">
        <v-btn
            color="primary"
            @click="dialog = true"
            depressed
            fab
        ><v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog
            v-model="dialog"
            v-if="dialog"
            max-width="500"
            persistent
            @keydown.esc="close"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h6">「学びの内容」を追加</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-form ref="form" @submit.prevent>
                                <v-text-field
                                    v-model="content"
                                    label=""
                                    persistent-hint
                                    :rules="[rules.max100chars, rules.required]"
                                    counter
                                    maxlength="100"
                                    autofocus
                                    @keydown.enter="trigger"
                                >
                                    <template v-slot:label>
                                        <span>学びの内容<span class="star">*</span></span>
                                    </template>
                                </v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="close"
                        depressed
                    >キャンセル</v-btn>
                    <v-btn
                        color="primary"
                        @click="add"
                        depressed
                        :disabled="!content"
                    >追加</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<style scoped>

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'MatrixItemAddButton',
    data () {
        return {
            dialog: false,
            content: '',
            rules: {
				required: value => !!value || '必須',
				max100chars: value => value.length <= 100 || '最大100字',
			},
        }
    },
    computed: {
        ...mapGetters({
            matrixItemAnswers: 'stateMatrixItemAnswers',
            matrixItemNotAnswered: 'stateMatrixItemNotAnswered',
            items: 'stateMatrixEmptyItemAnswers'
        })
    },
    methods: {
        ...mapActions([
            'addMatrixItemAnswer',
            'setMatrixItemNotAnswered',
            'addMatrixEmptyItemAnswer'
        ]),
        close () {
            this.$refs.form.reset()
            this.content = ''
            this.dialog = false
        },
        trigger (event) {
            if (event.keyCode !== 13) {
                return
            } else {
                this.add()
            }
        },
        add () {
            if (this.content) {
                let newId = 39 + this.items.length + 1
                let newItem = {
                    id: newId,
                    text: this.content,
                    value_1: false,
                    value_2: false,
                    value_3: false,
                    value_4: false,
                    value_5: false
                }
                this.addMatrixEmptyItemAnswer(newItem)
                this.setMatrixItemNotAnswered([newItem])
                this.$refs.form.reset()
                this.content = ''
                this.dialog = false
            } else {
                this.$refs.form.validate()
            }
        }
    }
}
</script>