<template>
	<div>
		<v-lazy
			:options="{
				threshold: 0.3
			}"
			min-height="200"
			transition="scroll-y-reverse-transition"
		>
		<div>
			<v-card
				class="mx-auto ma-2"
				max-width="1200"
				tile
				flat
				:id="'card-' + index"
			>
				<v-card-title>
					<span class="index" :id="'scroll-to-' + index">{{ index }}</span>
					<v-spacer></v-spacer>
					<span
						class="item"
						:class="$vuetify.breakpoint.lgAndUp ? 'text-h6' : 'text-h6 item-smallscreen'"
					>
						{{ item.text }}
					</span>
					<v-spacer></v-spacer>
					<span v-if="isOthers">
						<v-btn
							depressed
							fab
							small
							outlined
							color="error"
							@click="deleteItem(item)"
						>
							<v-icon>
								mdi-close
							</v-icon>
						</v-btn>
					</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-spacer></v-spacer>
						<v-col :cols="$vuetify.breakpoint.lgAndUp ? 8 : 11">
							<v-checkbox
								v-model="item.value_1"
								:disabled="item.value_5 || item.value_3 || item.value_4"
								@click="answer(item)"
								hide-details
							>
								<template v-slot:label>
									<div :class="item.value_5 || item.value_3 || item.value_4 ? '' : 'black-label'">司書課程で学び、興味を惹かれた</div>
								</template>
							</v-checkbox>
							<v-checkbox
								v-model="item.value_2"
								:disabled="item.value_5 || item.value_3 || item.value_4"
								@click="answer(item)"
								hide-details
							>
								<template v-slot:label>
									<div :class="item.value_5 || item.value_3 || item.value_4 ? '' : 'black-label'">司書課程で学び、仕事に役立った</div>
								</template>
							</v-checkbox>
							<v-checkbox
								v-model="item.value_3"
								:disabled="item.value_5 || item.value_1 || item.value_2"
								@click="answer(item)"
								hide-details
							>
								<template v-slot:label>
									<div :class="item.value_5 || item.value_1 || item.value_2 ? '' : 'black-label'">司書課程ではあまり学ばなかったと思うが、興味を惹かれる</div>
								</template>
							</v-checkbox>
							<v-checkbox
								v-model="item.value_4"
								:disabled="item.value_5 || item.value_1 || item.value_2"
								@click="answer(item)"
								hide-details
							>
								<template v-slot:label>
									<div :class="item.value_5 || item.value_1 || item.value_2 ? '' : 'black-label'">司書課程ではあまり学ばなかったと思うが、仕事のために学びたかった</div>
								</template>
							</v-checkbox>
							<v-checkbox
								v-model="item.value_5"
								:disabled="item.value_1 || item.value_2 || item.value_3 || item.value_4"
								@click="answer(item)"
								hide-details
							>
								<template v-slot:label>
									<div :class="item.value_1 || item.value_2 || item.value_3 || item.value_4 ? '' : 'black-label'">あてはまるものはない</div>
								</template>
							</v-checkbox>
						</v-col>
						<v-spacer></v-spacer>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
		</v-lazy>
		<v-divider></v-divider>
	</div>
</template>

<style scoped>
/* vuetify lg min breakpoint: 1280 - 16     = 1264px */
/* vuetify lg max breakpoint: 1920 - 16 - 1 = 1903px */

@media (min-width: 1264px) and (max-width: 1903px) {
    .flex.lg5-custom {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
}

.item {
	font-family: "Helvetica Neue", "Helvetica", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
	margin-left: 1em;
	margin-right: 1em;
	text-align: left;
}

.item-smallscreen {
	margin-left: 2em;
}

.index {
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
	font-size: 1.8em;
	font-weight: 600;
	color: lightgray;
}

.v-divider {
	border-width: 0.5px;
}

.black-label {
	color: #000;
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'MatrixAnswerCard',
	props: ["item", "index", "isOthers"],
	computed: {
		...mapGetters({
			matrixItemAnswers: 'stateMatrixItemAnswers',
			matrixItemNotAnswered: 'stateMatrixItemNotAnswered'
		}),
	},
	methods: {
		...mapActions([
			'addMatrixItemAnswer',
			'setMatrixItemNotAnswered',
			'deleteMatrixItem'
		]),
		answer(item) {
			if (item.value_5 == true) {
				item.value_1 = false
				item.value_2 = false
				item.value_3 = false
				item.value_4 = false
			}
			this.addMatrixItemAnswer(item)
		},
		deleteItem(item) {
			this.deleteMatrixItem(item)
		}
	}
}
</script>
